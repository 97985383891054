import './App.css';
import Landing from './components/Landing.tsx';

function App() {
  return (
    <div className="h-screen w-screen">
      <Landing/>
    </div>
  );
}

export default App;
