import React from 'react'
import logo from '../files/TempLogo.png'

const Navbar = () => {
    return (
        <div className='w-screen top-0 h-[10vh] bg-white flex justify-center items-center'>
            <nav className="w-[98%] top-0 h-5/6 bg-white flex items-center justify-left justify-between">
                <div className='flex h-full items-center'>
                    <img src={logo} alt='Could Not Load Logo' className='h-full left-0 mr-5'/>
                    <h1 className='text-black sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-mono'>Retro Shisha</h1>
                </div>
                <h2 className='text-black sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-mono'>Coming Soon!</h2>
            </nav>
        </div>
    )
}

export default Navbar;