import React from 'react';
import Navbar from './Navbar';

const Landing = () => {
    return (
        <div className="w-screen h-screen max-w-full bg-black">
            <Navbar/>
            <div className="w-screen h-[90vh] bg-[url('files/portrait_bowl.jpg')]  lg:bg-[url('files/landscape_bowl.png')] bg-center bg-no-repeat bg-cover flex justify-center">
                <div className='w-3/4 h-[90%] flex flex-col items-center justify-center'>
                    <h1 className='text-white text-4xl lg:text-7xl font-bold font-mono text-center'>
                        Retro Shisha
                    </h1>
                    <h2 className='text-white text-xl lg:text-4xl font-mono text-center'>
                        Delivering High Quality Shisha and Hookah Supplies Locally 
                    </h2>
                    <p className='text-white text-lg lg:text-2xl font-thin font-mono text-center'>
                        A Maryland-based Shisha wholesale and delivery startup founded in 2023. We focus on providing an enthusiast-selected collection of Shisha 
                        brands and quick delivery to Hookah lounges in the D.C., Maryland, and Virginia area enabling our clients to build a quality, varied menu of 
                        offerings for their hookah lounges.
                    </p>
                </div>
            </div>
        </div>
    )
};

export default Landing;
